<template>
    <div class="page-error">
        <div>
			<img src="@/assets/images/404.png" />
			<p>找不到该页面，你可以 <router-link to="/onlinePrice" class="return-home">返回首页</router-link></p>
		</div>
    </div>
</template>
<script>
export default {
	name:'Page404',
	methods:{
		returnHome(){

		}
	}
}
</script>
<style lang="scss" scoped>
.page-error{
	text-align: center;
	padding-top: 50px;
}
.return-home{
	display: inline-block;
	width: 110px;
    height: 36px;
    background: #1482f0;
    border-radius: 100px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    line-height: 36px;
    cursor: pointer;
	text-decoration: none;
	&:hover{
		background: #66b1ff;
	}
}
</style>